<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id"
    entity-name="人员"
    add-btn="添加人员"
    hide-show-detail
    hide-edit
    show-row-num
    :before-edit="beforeEdit"
    :before-update="beforeUpdate"
    :rules="rules"
    :columns="columns"
    :search-key-type="searchKeyType"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item>
        <a-select
          style="width: 180px;"
          placeholder="选择权限类型"
          @change="updateList"
          :options="doorRoles"
          v-model="form.role"
          allowClear
        ></a-select>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="人员" prop="user_id">
        <select-book type="user" v-model="form.user_id"></select-book>
      </a-form-model-item>
      <a-form-model-item label="权限类型" prop="role">
        <a-select placeholder="选择权限类型" :options="doorRoles" v-model="form.role" allowClear></a-select>
      </a-form-model-item>
      <a-form-model-item label="允许时间" prop="time_range" v-if="form.role == 3">
        <a-range-picker
          v-model="form.time_range"
          :placeholder="['开始时间', '结束时间']"
          :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm"
          value-format="YYYY-MM-DD HH:mm"
          allowClear
        />
      </a-form-model-item>
    </template>
  </data-list>
</template>
<script>
import { clone, getKeyTitle, stringConvertTimestamp, timestampConvertString } from "../../common/js/tool";
import { doorRoles } from "../../common/constant/space";

export default {
  name: "AccessControlAuth",
  data() {
    return {
      url: "/admin/smart-entrance-user",
      searchKeyType: {},
      columns: [
        {
          title: "人员",
          dataIndex: "user_id",
          customRender: (text) => {
            return <open-data type="userName" openid={text} />;
          },
        },
        {
          title: "权限",
          dataIndex: "role",
          customRender: (text) => {
            return <span>{getKeyTitle(doorRoles, text, "value", "label")}</span>;
          },
        },
        {
          title: "允许时间",
          customRender: (text) => {
            let res = "永久";
            let { role, time_range } = text;
            if (role == 3) {
              // 临时人员
              time_range = this.getRangeTime(time_range);
              res = time_range ? <span>{time_range[0] + " ~ " + time_range[1]}</span> : "-";
            }
            return res;
          },
        },
      ],
      rules: {
        user_id: [{ required: true, message: "请选择人员", trigger: "change" }],
        role: [{ required: true, message: "请选择权限类型", trigger: "change" }],
        time_range: [{ required: true, message: "请选择允许时间", trigger: "change" }],
      },
      doorRoles,
    };
  },
  computed: {
    dataList() {
      return this.$refs.dataList;
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getRangeTime(time) {
      if (time) {
        time = JSON.parse(time);
        let startTime = timestampConvertString(time[0], "yyyy-MM-dd HH:mm");
        let endTime = timestampConvertString(time[1], "yyyy-MM-dd HH:mm");
        return [startTime, endTime];
      } else {
        return time;
      }
    },
    beforeEdit(form, fn) {
      if (form.role == 3) {
        // 临时人员
        let time_range = form.time_range;
        if (time_range) {
          form.time_range = this.getRangeTime(time_range);
        }
      }
      fn(form);
    },
    beforeUpdate(form, fn) {
      let res = clone(form);
      if (res.role == 3) {
        // 临时人员
        let time_range = res.time_range;
        if (time_range && time_range.length > 0) {
          let startTime = stringConvertTimestamp(time_range[0]);
          let endTime = stringConvertTimestamp(time_range[1]);
          res.time_range = JSON.stringify([startTime, endTime]);
        }
      } else {
        res.time_range = undefined;
      }
      if (res.user_id && res.user_id.length > 0) {
        res.user_id = res.user_id[0];
      }
      res.entrance_id = this.id;
      fn(res);
    },
    updateList() {
      this.dataList.updateList();
    },
  },
};
</script>

<style lang="less" scoped></style>
